<template>
  <a-modal
    v-model="isVisible"
    title="Subscription Plans"
    width="100"
    class="customize-modal responsive_modal"
    @cancel="close"
    @ok="close"
  >
    <a-spin :spinning="spinning">
      <a-row type="flex" :gutter="10">
        <a-col :xs="24" :sm="24" :md="24" :lg="8">
          <div class="gx-package">
            <div class="gx-package-header gx-bg-cyan gx-text-white">
              <h2 class="gx-price"><i class="icon icon-halfstar"></i>Silver</h2>
              <p
                class="gx-letter-spacing-base gx-text-white gx-text-uppercase gx-mb-0"
              >
                <span>€9.99/month</span>
              </p>
            </div>
            <div class="gx-package-body">
              <ul class="gx-package-items">
                <li>
                  <a-icon type="user" />
                  Up to 200 Club Members
                </li>
                <li>
                  <a-icon type="team" />
                  20 Club Coach
                </li>
                <li>
                  <a-icon type="euro" />
                  2.9% + €0.30 Transaction processing rate
                </li>
                <li>
                  <a-icon type="safety-certificate" />
                  Monthly Payouts
                </li>
              </ul>
              <div class="gx-package-footer">
                <a-button
                  v-if="sub && sub.title == 'Silver'"
                  class="gx-btn-cyan"
                >
                  Active Plan
                </a-button>
                <a-button
                  v-else
                  class="gx-btn-cyan"
                  @click="attemptUpgrade(silverPlan, 1)"
                >
                  {{ sub.title == "Gold" ? "downgrade plan" : "Select Plan" }}
                </a-button>
              </div>
            </div>
          </div>
        </a-col>
        <a-col :xs="24" :sm="24" :md="24" :lg="8">
          <div class="gx-package">
            <div class="gx-package-header gx-bg-cyan gx-text-white">
              <h2 class="gx-price"><i class="icon icon-halfstar"></i>Gold</h2>
              <p
                class="gx-letter-spacing-base gx-text-white gx-text-uppercase gx-mb-0"
              >
                <span>€19.99/month</span>
              </p>
            </div>
            <div class="gx-package-body">
              <ul class="gx-package-items">
                <li>
                  <a-icon type="user" />
                  Up to 500 Club Members
                </li>
                <li>
                  <a-icon type="team" />
                  50 Club Coach
                </li>
                <li>
                  <a-icon type="euro" />
                  2.9% + €0.30 Transaction processing rate
                </li>
                <li>
                  <a-icon type="safety-certificate" />
                  Monthly Payouts
                </li>
              </ul>
              <div class="gx-package-footer">
                <a-button v-if="sub && sub.title == 'Gold'" class="gx-btn-cyan">
                  Active Plan
                </a-button>
                <a-button
                  v-else
                  class="gx-btn-cyan"
                  @click="attemptUpgrade(goldPlan, 2)"
                >
                  {{ sub.title == "Silver" ? "Upgrade Plan" : "Select Plan" }}
                </a-button>
              </div>
            </div>
          </div>
        </a-col>
        <a-col :xs="24" :sm="24" :md="24" :lg="8">
          <div class="gx-package">
            <div class="gx-package-header gx-bg-primary gx-text-white">
              <h2 class="gx-price">
                <i class="icon icon-halfstar"></i>Unlimited
              </h2>
              <p
                class="gx-letter-spacing-base gx-text-white gx-text-uppercase gx-mb-0"
              >
                <span>Contact Us!</span>
              </p>
            </div>
            <div class="gx-package-body">
              <ul class="gx-package-items">
                <li>
                  <a-icon type="user" />
                  Unlimited Club Members
                </li>
                <li>
                  <a-icon type="team" />
                  Unlimited Club Coaches
                </li>
                <li>
                  <a-icon type="euro" />
                  Tailored transaction fees
                </li>
                <li>
                  <a-icon type="safety-certificate" />
                  Tailored Payouts
                </li>
              </ul>
              <div class="gx-package-footer">
                <a-button type="primary" @click="contactForm">
                  Contact Us
                </a-button>
              </div>
            </div>
          </div>
        </a-col>
      </a-row>
    </a-spin>
    <div slot="footer" />
  </a-modal>
</template>

<script>
import nTime from "@/mixins/time";
import nCurrency from "@/mixins/currency";
import { billingService } from "@/common/api/api.service";
import notifications from "@/common/notifications/notification.service";
import {
  AUTH_SUBSCRIPTION,
  AUTH_USER,
} from "../../../store/modules/auth/auth-actions";
import { mapGetters } from "vuex/dist/vuex.common.js";
export default {
  name: "SubscriptionUpgradeModal",
  mixins: [nTime, nCurrency],
  props: {
    visible: {
      default: false,
      required: true,
      type: Boolean,
    },
  },
  data() {
    return {
      spinning: false,
      contactFormModal: false,
      silverPlan: "price_1R7u0DCg8u1Dv8tMp4r1V8sR", //process.env.SILVER_PLAN,
      goldPlan: "price_1R7u1eCg8u1Dv8tMjpzkC7og", //process.env.GOLD_PLAN,
    };
  },

  computed: {
    isVisible: {
      get() {
        return this.visible;
      },
      set() {
        return false;
      },
    },
    ...mapGetters({
      user: AUTH_USER,
      sub: AUTH_SUBSCRIPTION,
    }),
  },
  methods: {
    attemptUpgrade(plan, id) {
      this.spinning = true;
      billingService
        .attemptUpgrade({
          planId: plan,
          id: id,
        })
        .then((resp) => {
          this.spinning = false;
          if (resp.data.success) {
            this.close();
            notifications.success("Successfully upgraded your account.");
          } else {
            notifications.warn(resp.data.message);
          }
        })
        .catch(() => {
          this.spinning = false;
          notifications.warn(
            "We could not upgrade your account, please ensure your account has a valid card or contact support."
          );
        });
    },
    contactForm() {
      this.$emit("openModal");
    },
    close() {
      this.$emit("close");
    },
  },
};
</script>
<style>
.responsive_modal .ant-modal-content {
  height: 90vh !important;
  overflow: auto !important;
}
</style>
