var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a-card",
    {
      staticClass:
        "gx-card-widget gx-card-widget gx-card-full gx-p-3 gx-bg-red gx-text-white",
      class: [{ "gx-bg-cyan": _vm.sub && _vm.sub.active }],
    },
    [
      _c("div", { staticClass: "gx-media" }, [
        _c(
          "div",
          { staticClass: "gx-mr-2 gx-mr-xxl-12 text-center" },
          [
            _c("a-icon", {
              staticClass: "gx-fs-icon-lg",
              attrs: { type: "euro" },
            }),
          ],
          1
        ),
        _c(
          "div",
          { staticClass: "gx-media-body" },
          [
            _c(
              "a-row",
              { attrs: { type: "flex" } },
              [
                _vm.sub && _vm.sub.active
                  ? _c(
                      "a-col",
                      { staticClass: "gx-px-0", attrs: { span: 24 } },
                      [
                        _c("h4", { staticClass: "gx-text-white" }, [
                          _vm._v(
                            "\n            Great! You have an active subscription\n          "
                          ),
                        ]),
                        _c("p", [
                          _c("strong", [
                            _vm._v(_vm._s(_vm.sub.members) + " Members"),
                          ]),
                          _c(
                            "strong",
                            [
                              _vm._v(_vm._s(_vm.sub.admins) + " Club Admin"),
                              _vm.sub.admins > 1 ? [_vm._v("s")] : _vm._e(),
                            ],
                            2
                          ),
                        ]),
                        _c("p", { staticStyle: { padding: "0" } }, [
                          _vm._v(
                            "\n            Renew date:\n            " +
                              _vm._s(_vm.sub.current_period_end) +
                              "\n          "
                          ),
                        ]),
                      ]
                    )
                  : _c("a-col", { attrs: { span: 24 } }, [
                      _c(
                        "h4",
                        {
                          staticClass: "gx-text-white",
                          staticStyle: { "margin-bottom": "0" },
                        },
                        [
                          _vm._v(
                            "\n            You currently have no subscription, please upgrade to start using\n            SubsApp\n          "
                          ),
                        ]
                      ),
                    ]),
                _c(
                  "a-col",
                  { staticClass: "gx-pt-3 gx-px-0", attrs: { span: 24 } },
                  [
                    !_vm.sub || !_vm.sub.active
                      ? _c(
                          "a-button",
                          {
                            staticClass: "subscription-btn",
                            attrs: { type: "primary" },
                            on: { click: _vm.openUpgradeModal },
                          },
                          [
                            _vm._v(
                              "\n            Select Subscription\n          "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.sub && _vm.sub.active && _vm.sub.title == "Gold"
                      ? _c(
                          "a-button",
                          {
                            staticClass: "subscription-btn",
                            attrs: { type: "primary" },
                            on: { click: _vm.openUpgradeModal },
                          },
                          [
                            _vm._v(
                              "\n            Downgrade Subscription\n          "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.sub && _vm.sub.active && _vm.sub.title == "Silver"
                      ? _c(
                          "a-button",
                          {
                            staticClass: "subscription-btn",
                            attrs: { type: "primary" },
                            on: { click: _vm.openUpgradeModal },
                          },
                          [
                            _vm._v(
                              "\n            Upgrade Subscription\n          "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm.sub && _vm.sub.active
                      ? _c(
                          "a-popconfirm",
                          {
                            staticClass: "subscription-btn",
                            attrs: {
                              placement: "top",
                              "ok-text": "Yes",
                              "cancel-text": "No",
                            },
                            on: { confirm: _vm.cancelSubscription },
                          },
                          [
                            _c("template", { slot: "title" }, [
                              _c("p", [
                                _vm._v(
                                  "Are you sure to cancel the subscription"
                                ),
                              ]),
                            ]),
                            _c("a-button", { attrs: { type: "danger" } }, [
                              _vm._v("Cancel Subscription"),
                            ]),
                          ],
                          2
                        )
                      : _vm._e(),
                  ],
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("subscription-upgrade-modal", {
        attrs: { visible: _vm.upgradeModal },
        on: { close: _vm.closeUpgradeModal, openModal: _vm.openModal },
      }),
      _c("contact-form-modal", {
        attrs: { "show-contact-form-modal": _vm.showContactFormModal },
        on: {
          close: function ($event) {
            _vm.showContactFormModal = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }